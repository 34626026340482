export const CTA_LINK_TEXT = [
  "Buy now",
  "Learn more",
  "Sign up",
  "Subscribe",
  "Shop now",
  "Get discount",
];


export const ERROR_MESSAGE_MAPPING = {
  "2003":  "Current password is incorrect"
}