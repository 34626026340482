import React, { useEffect } from "react";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MainMenu from "../components/DrawerMenu";
import { useAuth } from "../auth/auth";
import env from "react-dotenv";
import { getEnvironment, getTimeSinceBuild } from "../utilities/environment";
import { Button, Card, Menu, MenuItem, ThemeProvider } from "@mui/material";
import { determineBaseURL } from "../constants/apiURLs";
import { createTheme } from "@mui/material/styles";
import { AccountCircle } from "@mui/icons-material";

function Copyright(props) {
  const auth = useAuth();
  // Get first 8 characters of commit ID (if it exists)
  const commitID = env?.COMMIT_REF
    ? env.COMMIT_REF.substring(0, 8)
    : "live local";

  return (
    <>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        Copyright ©
        <Link color="inherit" href="https://kahaniapp.com">
          Kahani
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        Kahani is a{" "}
        <Link color="inherit" href="https://gateway.xyz">
          Gateway X
        </Link>{" "}
        company
      </Typography>

      <Card
        severity="info"
        title="Test"
        style={{
          margin: "10px",
          width: "50%",
          minWidth: "300px",
          maxWidth: "500px",
          marginLeft: "auto",
          marginRight: "auto",
          padding: "10px",
          textAlign: "center",
          borderRadius: "5px",
          boxShadow: "0px 0px 10px #00000029",
        }}
      >
        <Typography variant="h6" color="text.secondary" align="center">
          Build Information
        </Typography>
        <Divider style={{ maxWidth: "200px", margin: "auto" }} />
        <Typography variant="body2" color="#e65100" align="center">
          {commitID}
          {/* Show build date only if not on shopify */}
          {!auth.isShopifyAccount && <>from {getTimeSinceBuild()}</>}
        </Typography>
        <Typography variant="body2" color="#e65100" align="center">
          Env: {getEnvironment()}
        </Typography>
        <Typography variant="body2" color="#e65100" align="center">
          API: {determineBaseURL()}
        </Typography>
      </Card>
    </>
  );
}

const drawerWidth = 240;

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(122, 87, 209)",
    },
    secondary: {
      main: "rgba(91, 231, 196)",
    },
    info: {
      main: "rgba(79, 214, 255)",
    },
    success: {
      main: "rgba(122, 87, 209)",
    },
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => !["open", "isAuthenticated"].includes(prop),
})(({ theme, open, isAuthenticated }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${isAuthenticated ? drawerWidth : 0}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const Page = (props) => {
  useEffect(() => {
    if (props.title) {
      document.title = `${props.title} | Kahani`;
    } else {
      document.title = "Kahani";
    }
  });

  let auth = useAuth();

  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    // app bar starts closed if the screen is smaller than 400 px wide
    if (window.innerWidth < 700) {
      setOpen(false);
    }
  }, []);

  // Set background color based on environment
  // leave both these blank on production
  let backgroundColor = "";
  let environmentNote = "";
  switch (getEnvironment()) {
    case "localhost":
      backgroundColor = "#2D882D";
      environmentNote = "Localhost build";
      break;
    case "development":
      backgroundColor = "#AA9E39";
      environmentNote = "Development build";
      break;
    case "pull_request":
      backgroundColor = "#882D60";
      environmentNote = "Pull request build";
      break;
    case "unknown":
      backgroundColor = "#FE0006";
      environmentNote = "Unknown build";
      break;
    default:
      backgroundColor = "#000";
      break;
  }

  const pullRequestLink = (
    <>
      &mdash;
      <Button
        href={
          "https://github.com/gatewayx/kahani-portal/pull/" + env?.REVIEW_ID
        }
        target="github-pr"
        color="primary"
        variant="contained"
      >
        Open PR on Github
      </Button>
    </>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          isAuthenticated={auth.isAuthenticated}
          position="absolute"
          open={open}
        >
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
              backgroundColor: backgroundColor,
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              <img
                src={require("../assets/logo.png")}
                alt="invenstory icon"
                style={{
                  marginRight: "8px",
                  verticalAlign: "middle",
                  height: "32px",
                }}
              />
              {environmentNote}

              {/* If pull request, then show the link too */}
              {getEnvironment() === "pull_request" && pullRequestLink}
            </Typography>
            {auth.isAuthenticated && (
              <div>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />{" "}
                  <span style={{ fontSize: "1rem" }}>{auth.user.name}</span>
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={auth.signout}>Logout</MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>
        {auth.isAuthenticated && (
          <Drawer variant="permanent" open={open}>
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                px: [1],
              }}
            >
              <IconButton
                onClick={toggleDrawer}
                aria-label="Collapse Menu Drawer"
              >
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <MainMenu></MainMenu>
          </Drawer>
        )}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            {props.children}

            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

function Title(props) {
  const id = props?.id ? props.id : null;

  return (
    <Typography
      id={id}
      component="h2"
      variant="h6"
      color="primary"
      gutterBottom
    >
      {props.children}
    </Typography>
  );
}
Page.Title = Title;

export default Page;
