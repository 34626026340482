import { useQuery } from "react-query";

import find from "lodash/find";

import { ctaURLs } from "../constants/apiURLs";
import request from "../utilities/request";

export const queryKey = "CTA_REQUEST";

export const useCTAs = () => {
  const { data, ...meta } = useQuery(queryKey, () => {
    return request().get(ctaURLs.list());
  });

  return {
    ctas: data?.ctas ?? [],
    ...meta,
  };
};

export const useCTA = (ctaID) => {
  const { ctas, ...meta } = useCTAs();
  const cta = find(ctas, { id: ctaID });

  return {
    cta: cta ?? {},
    ...meta,
  };
};
