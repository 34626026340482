import React from "react";

import { useAccounts } from "../../api/fetchAccountsData";

import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import { requestAccountID, setRequestAccount } from "../../auth/auth";
import {
  TextField,
  TableBody,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";

import Fuse from "fuse.js";
import { useNavigate } from "react-router";

const AccountList = () => {
  const navigate = useNavigate();
  const { error, isLoading, accounts } = useAccounts();

  const [searchTerm, setSearchTerm] = React.useState("");
  const [filteredAccounts, setFilteredAccounts] = React.useState(accounts);
  const [sortedAccounts, setSortedAccounts] = React.useState(filteredAccounts);

  // Grab the account list when it or any of the filters change
  React.useEffect(() => {
    filterAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, accounts]);

  const filterAccounts = () => {
    // Return nothing if no accounts
    if (!accounts || accounts.length === 0) {
      setFilteredAccounts([]);
      return;
    }

    // Copy accounts
    let _filteredAccounts = [...accounts];

    // Filter the accounts by search term
    if (searchTerm) {
      const fuse = new Fuse(_filteredAccounts, {
        keys: ["name", "id", "ga_ua_tracking_id"],
        threshold: 0.5,
      });

      // Run search
      const results = fuse.search(searchTerm);

      // Convert results back into original array type
      _filteredAccounts = results.map((result) => result.item);
    }

    // Set filtered accounts
    setFilteredAccounts(_filteredAccounts);
  };

  // When the filtered accounts or sort params change, re-sort
  React.useEffect(() => {
    sortAccounts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredAccounts]);

  const sortAccounts = () => {
    // Return nothing if no accounts
    if (!filteredAccounts || filteredAccounts.length === 0) {
      setSortedAccounts([]);
      return;
    }

    // Copy filtered accounts
    let _sortedAccounts = [...filteredAccounts];

    // Sort the accounts by name
    _sortedAccounts = _sortedAccounts.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    // Set sorted accounts
    setSortedAccounts(_sortedAccounts);
  };

  const selectAccount = (account) => {
    // Set the account in local storage
    setRequestAccount(account);

    // Redirect to the collections page
    navigate("/collections");
  };

  return (
    <>
      <LoadingBackdrop loading={isLoading} />
      {/* A table showing all of the accounts */}

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Search"
            variant="outlined"
            margin="normal"
            placeholder="Search media tags or metadata"
            fullWidth
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">{accounts.length} accounts</Typography>
        </Grid>
        <Grid item xs={12}>
          {/* Table of each account */}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedAccounts.map((account) => (
                <TableRow key={account.id}>
                  <TableCell>{account.name}</TableCell>
                  <TableCell>{account.id}</TableCell>
                  <TableCell>
                    {account.admin ? (
                      "Admin Account"
                    ) : (
                      // If we are already logged in as this user, then don't allow them to select it

                      <Button
                        variant="contained"
                        color="secondary"
                        disabled={account.id === requestAccountID()}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          selectAccount(account);
                        }}
                      >
                        Select
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>

      {error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      )}
    </>
  );
};

export default AccountList;
