import { apiCall } from "../utilities/request";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { accountURLs } from "../constants/apiURLs";
import {
  clearCookie,
  getUser,
  setCookie,
  getAuthToken,
  getAccountID,
} from "../utilities/parseCookie";
import jwt_decode from "jwt-decode";

let AuthContext = React.createContext(null);

export function getOverrideAccountID() {
  return localStorage.getItem("overrideAccountID");
}

// This function will check local storage for an overridden account ID (an admin
// assuming the identity of another user) and return it. If there is no override
// account ID, it will return the logged in users ID.
export function requestAccountID() {
  // Check local storage for an override account ID
  const overrideAccountID = getOverrideAccountID();
  if (overrideAccountID) {
    return overrideAccountID;
  }

  return getAccountID();
}

export function overrideAccountName() {
  return localStorage.getItem("overrideAccountName");
}

// An admin will call this with an accountID that they want to assume the identity
export function setRequestAccount(account) {
  localStorage.setItem("overrideAccountID", account.id);
  localStorage.setItem("overrideAccountName", account.name);
}
export function clearRequestAccountID() {
  localStorage.removeItem("overrideAccountID");
  localStorage.removeItem("overrideAccountName");
}
// Check if we have selected an override
export function hasOverrideAccountID() {
  return localStorage.getItem("overrideAccountID") ? true : false;
}

function AuthProvider({ children }) {
  // Check the cookie for a user
  const cookieUser = getUser();
  const cookieToken = getAuthToken();

  const isAlreadyAuthenticated = loginValid(cookieUser, cookieToken);

  let [user, setUser] = React.useState(cookieUser);
  let [token, setToken] = React.useState(cookieToken);
  let [isAuthenticated, setIsAuthenticated] = React.useState(
    isAlreadyAuthenticated
  );
  let [isAdmin, setIsAdmin] = React.useState(isAdminToken(cookieToken));

  let logInWithCredentials = (account, token, isAdmin) => {
    setUser(account);
    setToken(token);
    setIsAuthenticated(true);
    setIsAdmin(isAdmin);
    setCookie(account, token);
  };

  let signin = (username, password, success, failure) => {
    console.info("Attemping to sign in...");

    // perform the sign in post request
    apiCall
      .post(accountURLs.login(), {
        login_id: username,
        password: password,
      })
      .then((response) => {
        console.info("Sign in response: ", response);
        const newUser = response.data.account;
        const token = response.data.token;

        logInWithCredentials(newUser, token, isAdminToken(token));

        // If callback is set, run it
        if (success) {
          success({
            isAdmin: isAdminToken(token),
            user: newUser,
            token: token,
          });
        }
      })
      .catch((error) => {
        console.error("Sign in error: ");
        console.error(error);

        if (failure) {
          failure(error);
        }
      });
  };

  let signout = (callback) => {
    console.info("Attemping to sign out...");
    // Clear the cookies
    clearCookie();
    setUser(null);
    setToken(null);
    setIsAuthenticated(false);
    setIsAdmin(false);
    localStorage.removeItem("overrideAccountID");
    localStorage.removeItem("overrideAccountName");

    // If callback is set, run it
    if (callback) {
      callback();
    }
  };

  let isShopifyAccount = false;
  try {
    const claims = jwt_decode(token);
    if (claims?.login_id && claims?.login_id.includes("myshopify.com")) {
      isShopifyAccount = true;
    }
  } catch (e) {}

  let value = {
    user,
    logInWithCredentials,
    signin,
    signout,
    isAuthenticated,
    isAdmin,
    isShopifyAccount,
    token,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return React.useContext(AuthContext);
}

function RequireAuth({ adminOnly, children }) {
  let auth = useAuth();
  let location = useLocation();

  if (tokenExpired(auth.token)) {
    return (
      <Navigate
        to={{ pathname: "/login?exp", state: { from: location } }}
        replace
      />
    );
  }

  if (!auth.isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Some pages require admin access
  if (adminOnly && !auth.isAdmin) {
    return <Navigate to="/" replace />;
  }

  return children;
}

export { AuthProvider, useAuth, RequireAuth };

// Helper to validate that a JWT token and user are set in the cookie
function loginValid(user, token) {
  return user && token && tokenValid(token);
}

// Helper to check if a token is an admin token
function isAdminToken(token) {
  if (!tokenValid(token)) {
    return false;
  }

  // Parse JWT token claims
  const claims = JSON.parse(atob(token.split(".")[1]));

  // Check the admin claim
  return claims.admin;
}

function tokenExpired(token) {
  // Token should be 3 sections separated by a period
  if (!(token && token !== null && token.split(".").length === 3)) {
    return false;
  }

  let claims = null;
  try {
    claims = jwt_decode(token);
  } catch (e) {
    return false;
  }

  // Check if the token is expired
  return claims.exp < Math.floor(Date.now() / 1000);
}

function tokenValid(token) {
  // Token should be 3 sections separated by a period
  if (!(token && token !== null && token.split(".").length === 3)) {
    return false;
  }

  // Token should decode OK
  try {
    jwt_decode(token);
  } catch (e) {
    return false;
  }

  if (tokenExpired(token)) {
    return false;
  }

  return true;
}
