import React from "react";
import { QueryClientProvider } from "react-query";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import queryClient from "./constants/queryClient";
import { SnackbarProvider } from "notistack";

import { RequireAuth, AuthProvider, requestAccountID } from "./auth/auth";

// import Dashboard from "./pages/Dashboard";
// import Collections from "./pages/Collections";
// import EditCollection from "./pages/EditCollection";
// import Assets from "./pages/Assets";
// import LogIn from "./pages/Login";
// import NewCollection from "./pages/NewCollection";

import Page from "./components/Page";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useKonamiCode } from "./utilities/konamiCode";
import CTAs from "./pages/CTAs";
import Admin from "./pages/Admin";
import LivePreview from "./pages/LivePreview";

const Collections = React.lazy(() => import("./pages/Collections"));
const AccountSettings = React.lazy(() => import("./pages/AccountSettings"));
const EditCollection = React.lazy(() => import("./pages/EditCollection"));
const Assets = React.lazy(() => import("./pages/Assets"));
const LogIn = React.lazy(() => import("./pages/Login"));
const NewCollection = React.lazy(() => import("./pages/NewCollection"));
const AdminSettingsDialog = React.lazy(() =>
  import("./modules/adminSettings.jsx/adminSettingsDialog")
);
const Shopify = React.lazy(() => import("./pages/Shopify"));
const ShopifyCallback = React.lazy(() => import("./pages/ShopifyCallback"));
const PageTargeting = React.lazy(() => import("./pages/PageTargeting"));

const ShopifyAppBlock = React.lazy(() => import("./pages/ShopifyAppBlock"));

function App() {
  const konamiActivated = useKonamiCode();

  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider maxSnack={4}>
        <AuthProvider>
          <AdminSettingsDialog
            open={konamiActivated}
            onClose={() => {
              // After a few seconds, reload
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }}
          />
          <BrowserRouter>
            <RouteList />
          </BrowserRouter>
        </AuthProvider>
      </SnackbarProvider>
    </QueryClientProvider>
  );
}

export default App;

const RouteList = () => {
  const location = useLocation();
  React.useEffect(() => {
    if (requestAccountID()) {
      window.analytics.identify(requestAccountID());
    }
  }, [location.pathname]);

  return (
    <Routes>
      <Route
        path="/admin"
        element={
          <RequireAuth adminOnly={true}>
            <Admin />
          </RequireAuth>
        }
      />
      <Route
        path="/"
        element={
          <RequireAuth>
            <Collections />
          </RequireAuth>
        }
      />
      <Route
        path="/account"
        element={
          <RequireAuth>
            <AccountSettings />
          </RequireAuth>
        }
      />
      <Route
        path="/collections"
        element={
          <RequireAuth>
            <Collections />
          </RequireAuth>
        }
      />
      <Route
        path="/newCollection"
        element={
          <RequireAuth>
            <NewCollection />
          </RequireAuth>
        }
      />
      <Route
        path="/collections/:collectionID"
        element={
          <RequireAuth>
            <EditCollection />
          </RequireAuth>
        }
      />
      <Route
        path="/accounts/:accountID/collections/:collectionID/livePreview"
        element={<LivePreview />}
      />
      <Route
        path="/collections/:collectionID/stories/:storyID"
        element={
          <RequireAuth>
            <EditCollection />
          </RequireAuth>
        }
      />
      <Route
        path="/collections/:collectionID/stories/:storyID/chapters/:chapterID"
        element={
          <RequireAuth>
            <EditCollection />
          </RequireAuth>
        }
      />
      <Route
        path="/assets"
        element={
          <RequireAuth>
            <Assets />
          </RequireAuth>
        }
      />
      <Route
        path="/ctas"
        element={
          <RequireAuth>
            <CTAs />
          </RequireAuth>
        }
      />
      <Route
        path="/publish"
        element={
          <RequireAuth>
            <PageTargeting />
          </RequireAuth>
        }
      />
      <Route
        path="/shopifyInstallation"
        element={
          <RequireAuth>
            <ShopifyAppBlock />
          </RequireAuth>
        }
      />

      {/* Login page not protected */}
      <Route path="/login" element={<LogIn />} />

      {/* Shopify OAuth */}
      <Route path="/shopify" element={<Shopify />} />
      <Route path="/auth/callback" element={<ShopifyCallback />} />

      <Route path="/error/network" element={<NetErrorPage />} />

      {/* Not found page */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

function NotFoundPage() {
  return (
    <Page title="Not Found">
      <Grid container spacing={6}>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <Paper
            sx={{
              p: 4,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Page.Title>Page Not Found</Page.Title>
          </Paper>
        </Grid>
      </Grid>
    </Page>
  );
}

function NetErrorPage() {
  return (
    <Page title="Not Found">
      <Grid container spacing={6}>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <Paper
            sx={{
              p: 4,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Page.Title>Could not connect to Kahani</Page.Title>
            We're sorry, but we were unable to connect to Kahani's servers. This
            may be a problem with your internet connection, or with Kahani's
            servers. Please try again in a few minutes.
          </Paper>
        </Grid>
      </Grid>
    </Page>
  );
}
