import React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import { Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";

import Chip from "@mui/material/Chip";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";

import EmailIcon from "@mui/icons-material/Email";
import LinkIcon from "@mui/icons-material/Link";
import QuizIcon from "@mui/icons-material/Quiz";
import ListView from "./ListView";
/*
  discount_text: "15%"
  id: "f73957cc-c544-4d6e-a59e-4b0ada887f94"
  inUse: true
  internal_description: "Automatically created by the CMS tool"
  internal_name: "CTA for Step1"
  klaviyo_list_id: "SPiFmF"
  link_text: "Get discount"
  link_url: ""
  type: "klaviyo_email_discount_modal"
*/

const CTACard = ({ buttonLabel, cta, onSelect, viewType }) => {
  const inUseIcon = cta.inUse ? <CheckCircleIcon /> : <DoNotDisturbOnIcon />;
  const inUseLabel = cta.inUse ? "In Use" : "Not In Use";
  const inUseColor = cta.inUse ? "success" : "warning";

  // If button label is not set, use a default
  if (!buttonLabel) {
    buttonLabel = "Select";
  }

  let typeLabel = "";
  let typeIcon = null;
  switch (cta.type) {
    case "klaviyo_email_discount_modal":
      typeLabel = "Klaviyo";
      typeIcon = <EmailIcon />;
      break;
    case "link":
      typeLabel = "Link";
      typeIcon = <LinkIcon />;
      break;
    default:
      typeLabel = "Unknown";
      typeIcon = <QuizIcon />;
  }

  return (
    <>
    {
      viewType === "grid" ? <Card sx={{ width: "100%" }}>
      <CardContent>
        <Typography variant="h5" component="div">
          {cta.internal_name}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {cta.link_text}
        </Typography>
        <Typography variant="body2">{cta.internal_description}</Typography>
      </CardContent>
      <CardActions>
        <Stack
          direction="row"
          justify="space-between"
          align="center"
          spacing={1}
        >
          <Button
            size="small"
            onClick={(e) => {
              e.preventDefault();
              onSelect(cta);
            }}
            variant="contained"
          >
            {buttonLabel}
          </Button>
          <Chip
            icon={inUseIcon}
            label={inUseLabel}
            variant="outlined"
            color={inUseColor}
            size="small"
          />
          <Chip
            label={typeLabel}
            variant="outlined"
            color="primary"
            size="small"
            icon={typeIcon}
          ></Chip>
        </Stack>
      </CardActions>
        </Card> :
          <ListView
            cta={cta}
            buttonLabel={buttonLabel}
            inUseLabel={inUseLabel}
            inUseIcon={inUseIcon}
            inUseColor={inUseColor}
            onSelect={onSelect}
            typeIcon={typeIcon}
            typeLabel={typeLabel}
          />
    }
    </>
  );
};




export default CTACard;
