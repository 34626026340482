import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  Paper,
  TableBody,
  TableRow, 
  Stack,
  Button,
  Chip} from '@mui/material';
import React from 'react'
import map from "lodash/map";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import EmailIcon from "@mui/icons-material/Email";
import LinkIcon from "@mui/icons-material/Link";
import QuizIcon from "@mui/icons-material/Quiz";


const ListView = (props) => {
  const getCtaType = (cta) => {
  const inUseIcon = cta.inUse ? <CheckCircleIcon /> : <DoNotDisturbOnIcon />;
  const inUseLabel = cta.inUse ? "In Use" : "Not In Use";
  const inUseColor = cta.inUse ? "success" : "warning";
  const buttonLabel = props.buttonLabel || 'select';
    
    let typeLabel = "";
    let typeIcon = null;
    switch (cta.type) {
      case "klaviyo_email_discount_modal":
        typeLabel = "Klaviyo";
        typeIcon = <EmailIcon />;
        break;
      case "link":
        typeLabel = "Link";
        typeIcon = <LinkIcon />;
        break;
      default:
        typeLabel = "Unknown";
        typeIcon = <QuizIcon />;
    }

    return {
      typeLabel,
      typeIcon,
      inUseIcon,
      inUseColor,
      buttonLabel,
      inUseLabel
    }
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="CTA list view">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Link Text</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
                
          {map(props.filteredCTAs, (cta) => {
            const { typeLabel,
              typeIcon,
              inUseIcon,
              inUseColor,
              buttonLabel,
              inUseLabel } = getCtaType(cta)
            return (
            <TableRow
              key={cta.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{cta.internal_name}</TableCell>
              <TableCell>{cta.link_text}</TableCell>
              <TableCell>{cta.internal_description || 'N/A'}</TableCell>
            <TableCell>
              
              <Stack
                direction="row"
                justify="space-between"
                align="center"
                spacing={2}
              >
                <Button
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    props.onSelect(cta);
                  }}
                  variant="contained"
                >
                  {buttonLabel}
                </Button>
                <Chip
                  icon={inUseIcon}
                  label={inUseLabel}
                  variant="outlined"
                  color={inUseColor}
                  size="small"
                />
                <Chip
                  label={typeLabel}
                  variant="outlined"
                  color="primary"
                  size="small"
                  icon={typeIcon}
                ></Chip>
              </Stack>
              </TableCell>
            </TableRow>
          )
        })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ListView;