import React from "react";

import { useCTAs } from "../api/fetchCTAData";

import Page from "../components/Page";
import CTAList from "../modules/ctas/CTAList";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import LoadingBackdrop from "../components/LoadingBackdrop";

import CTAEditor from "../modules/ctas/CTAEditorForm";
import { Button, Stack } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

const CTAs = () => {
  const { error, isLoading, ctas } = useCTAs();

  const [selectedCTA, setSelectedCTA] = React.useState(null);
  const [metadataModalOpen, setMetadataModalOpen] = React.useState(false);

  return (
    <Page title="CTA Manager">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <LoadingBackdrop loading={isLoading} />
            <Page.Title>
              <Stack
                direction="row"
                justifyItems="space-between"
                alignItems="center"
              >
                <>CTA Library</>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setSelectedCTA(null);
                    setMetadataModalOpen(true);
                  }}
                  startIcon={<AddIcon />}
                  sx={{
                    marginLeft: "auto",
                  }}
                >
                  Create New CTA
                </Button>
              </Stack>
            </Page.Title>

            <CTAEditor.Modal
              show={metadataModalOpen}
              onClose={() => setMetadataModalOpen(false)}
              cta={selectedCTA}
            />

            {!error && (
              <CTAList
                onSelect={(cta) => {
                  setSelectedCTA(cta);
                  setMetadataModalOpen(true);
                }}
                ctas={ctas}
                buttonLabel="Edit"
              />
            )}
            {error && (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {error}
              </Alert>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Page>
  );
};

export default CTAs;
