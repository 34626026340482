import { useQuery } from "react-query";

import find from "lodash/find";
import flatMap from "lodash/flatMap";

import { collectionURLs } from "../constants/apiURLs";
import request from "../utilities/request";

import { queryKey as publishQueryKey } from "../api/fetchCollectionPublishStatus";
import queryClient from "../constants/queryClient";

export const queryKey = "COLLECTION_REQUEST";

export const collectionHasChanged = (collectionID) => {
  // Invalidate the collections
  queryClient.invalidateQueries(queryKey);

  // Invalidate publish status
  queryClient.invalidateQueries(publishQueryKey(collectionID));
};

export const useCollections = () => {
  const { data, ...meta } = useQuery(queryKey, () => {
    return request().get(collectionURLs.list());
  });

  return {
    collections: data?.collections ?? [],
    ...meta,
  };
};

export const useCollection = (collectionID) => {
  const { collections, ...meta } = useCollections();
  const collection = find(collections, { id: collectionID });

  return {
    collection: collection ?? {},
    ...meta,
  };
};

export const useCollectionStyle = (collectionID) => {
  const { collections } = useCollections();
  const collection = find(collections, { id: collectionID });

  return {
    style: collection.style ?? {},
  };
};

export const useStories = (collectionID) => {
  const { collections, ...meta } = useCollections();
  const stories = collectionID
    ? find(collections, { id: collectionID })?.stories
    : flatMap(collections, (collection) => collection.stories);

  return {
    stories: stories ?? [],
    ...meta,
  };
};

export const useStory = (storyID) => {
  const { stories, ...meta } = useStories();
  const story = find(stories, { id: storyID });

  return {
    story: story ?? {},
    ...meta,
  };
};

export const useChapters = (storyID) => {
  const { stories, ...meta } = useStories();
  const chapters = storyID
    ? find(stories, { id: storyID })?.chapters
    : flatMap(stories, (story) => story.chapters);

  return {
    chapters: chapters ?? [],
    ...meta,
  };
};

export const useChapter = (chapterID) => {
  const { chapters, ...meta } = useChapters();
  const chapter = find(chapters, { id: chapterID });

  return {
    chapter: chapter ?? {},
    ...meta,
  };
};
