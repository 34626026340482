import DashboardIcon from "@mui/icons-material/Dashboard";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useTheme } from "@mui/material/styles";
// import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";
import { Link, useResolvedPath, useMatch } from "react-router-dom";
import { hasOverrideAccountID, useAuth } from "../auth/auth";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import Admin from "../pages/Admin";
import SettingsIcon from "@mui/icons-material/Settings";
import InstallDesktopIcon from "@mui/icons-material/InstallDesktop";

export default function MainMenu() {
  // Dialog settings
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const auth = useAuth();

  return (
    <div>
      {/* Only show the admin menu if logged in as an admin */}
      {auth.isAdmin && (
        <>
          <List>
            <ListSubheader inset>Admin</ListSubheader>
            <ActiveLink
              to="/admin"
              label="Account Selector"
              icon={<PeopleOutlineIcon />}
            />
          </List>
          <Divider />
          <Admin.AccountOverrideAlert />
          <Divider />
        </>
      )}
      {/* Only show the other menus if we're not an admin OR we are an admin and have selected an account ID to assume */}
      {!auth.isAdmin || hasOverrideAccountID() ? (
        <>
          <List>
            <ListSubheader inset>Library</ListSubheader>
            <ActiveLink
              to="/collections"
              label="Collections"
              icon={<DashboardIcon />}
            />
            <ActiveLink to="/assets" label="Media" icon={<PermMediaIcon />} />
            <ActiveLink
              to="/ctas"
              label="CTAs"
              icon={<AddShoppingCartIcon />}
            />
          </List>
          <Divider />
          <List>
            <ListSubheader inset>Account</ListSubheader>
            <ActiveLink
              to="/account"
              label="Settings"
              icon={<SettingsIcon />}
            />
            <ActiveLink
              to="/publish"
              label="Publish"
              icon={<DocumentScannerIcon />}
            />
            <ActiveLink
              to="/shopifyInstallation"
              label="Shopify Installation"
              icon={<InstallDesktopIcon />}
            />
          </List>
        </>
      ) : null}
    </div>
  );
}

function ActiveLink({ children, to, ...props }) {
  const theme = useTheme();
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  const isActive = match ? true : false;

  // ID is the label with dashes instead of spaces & lower case
  const id = props.id
    ? props.id
    : "drawer-nav-" + props.label.replace(/ /g, "-").toLowerCase();

  return (
    <div>
      <Link
        style={{
          fontWeight: isActive ? "bold" : "",
          textDecoration: "none",
          color: theme.palette.grey[900],
        }}
        aria-label={props.label}
        to={to}
        {...props}
        id={id}
      >
        <ListItem button selected={isActive}>
          <ListItemIcon>{props.icon}</ListItemIcon>
          <ListItemText primary={props.label} />
        </ListItem>
      </Link>
    </div>
  );
}
