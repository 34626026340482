import React from "react";
import map from "lodash/map";

import CTACard from "./CTACard";

import {
  FormControl,
  FormControlLabel,
  Radio,
  Grid,
  RadioGroup,
  Typography,
  TextField,
  Stack,
  Alert,
  Grow,
} from "@mui/material";

import { useCollections } from "../../api/fetchCollectionData";

import Fuse from "fuse.js";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import GridViewIcon from '@mui/icons-material/GridView';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import './CTACard.css';
import ListView from "./ListView";


const CTAList = ({ buttonLabel, ctas, onSelect }) => {
  const [filteredCTAs, setFilteredCTAs] = React.useState(ctas);

  const [searchTerm, setSearchTerm] = React.useState("");
  const [viewType, setViewType] = React.useState("grid");
  const [typeFilter, setTypeFilter] = React.useState("all");
  const typeFilterChange = (e) => {
    setTypeFilter(e.target.value);
  };
  const [inUseFilter, setInUseFilter] = React.useState("all");
  const inUseFilterChange = (e) => {
    setInUseFilter(e.target.value);
  };

  // Get the collections on the account so we can see which CTAs are in use
  const { collections, collectionsLoading } = useCollections();
  const [ctaUsageTable, setCtaUsageTable] = React.useState([]);
  // When collections change, update the CTA usage table
  React.useEffect(() => {
    if (collections) {
      // Generate a table of CTA usage
      const ctaUsageTable = generateCTAUseTable(collections);
      setCtaUsageTable(ctaUsageTable);
    } else {
      setCtaUsageTable([]);
    }
  }, [collections]);

  // Onload, run ctas w/ the default filter
  // When any of the filter variables or source data changes, re-filter ctas
  React.useEffect(() => {
    filterCTAs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctas, searchTerm, typeFilter, inUseFilter, ctaUsageTable]);

  // Function to filter the ctas
  const filterCTAs = () => {
    // Don't run if there are no ctas
    if (!ctas || ctas.length === 0) {
      setFilteredCTAs([]);
      return;
    }

    // Copy ctas
    let _filteredCTAs = [...ctas];

    // For each CTA, check if it's in use & add a metadata field for it
    _filteredCTAs = ctas.map((cta) => {
      // Check if the CTA is in use
      cta.inUse = isCTAInUse(cta, ctaUsageTable);
      return cta;
    });

    // Filter ctas by currently selected type
    _filteredCTAs = _filteredCTAs.filter((cta) => {
      if (typeFilter === "all") {
        return true;
      } else {
        return cta.type === typeFilter;
      }
    });

    // Filter ctas by currently selected in use filter
    _filteredCTAs = _filteredCTAs.filter((cta) => {
      switch (inUseFilter) {
        case "all":
          return true;
        case "yes":
          return cta.inUse;
        case "no":
          return !cta.inUse;
        default:
          return false;
      }
    });

    // Filter ctas by search term using fuse.js if search term is set
    if (searchTerm) {
      const fuse = new Fuse(_filteredCTAs, {
        keys: [
          "internal_name",
          "internal_description",
          "link_text",
          "link_url",
        ],
        threshold: 0.3,
      });

      // Run search
      const results = fuse.search(searchTerm);

      //  Convert results back into the original array type
      _filteredCTAs = results.map((result) => result.item);
    }

    // Set CTAs
    setFilteredCTAs(_filteredCTAs);
  };

  return (
    <Grid container spacing={3}>
      <LoadingBackdrop loading={collectionsLoading} />
      <Grid item xs={12} md={6}>
        <Stack
          spacing={3}
          alignItems="center"
          justify="space-between"
          direction="row"
        >
          <FormControl>
            <Typography variant="h6">Type</Typography>
            <RadioGroup
              aria-label="type-filter"
              name="type-filter"
              value={typeFilter}
              onChange={typeFilterChange}
            >
              <FormControlLabel
                value="all"
                control={<Radio size="small" />}
                label="All"
              />
              <FormControlLabel
                value="klaviyo_email_discount_modal"
                control={<Radio size="small" />}
                label="Klaviyo Discount Modal"
              />
              <FormControlLabel
                value="link"
                control={<Radio size="small" />}
                label="Link"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">In Use</Typography>
            <RadioGroup
              aria-label="type-filter"
              name="type-filter"
              value={inUseFilter}
              onChange={inUseFilterChange}
            >
              <FormControlLabel
                value="all"
                control={<Radio size="small" />}
                label="All"
              />
              <FormControlLabel
                value="yes"
                control={<Radio size="small" />}
                label="Yes"
              />
              <FormControlLabel
                value="no"
                control={<Radio size="small" />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Search"
          variant="outlined"
          margin="normal"
          placeholder="Search media tags or metadata"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <ViewSwitch
          setViewType={setViewType}
          viewType={viewType}
        />
        
      </Grid>

    {
      viewType === "grid" ? (
      <Grid
        container
        spacing={3}
        sx={{
          padding: "2rem",
        }}
      >
        {map(filteredCTAs, (cta) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={cta.id}>
            <CTACard
              buttonLabel={buttonLabel}
              onSelect={onSelect}
              cta={cta}
              key={cta.id}
              viewType={viewType}
            />
          </Grid>
        ))}
          </Grid>) :

          <ListView
            filteredCTAs={filteredCTAs}
              viewType={viewType}
              buttonLabel={buttonLabel}
              onSelect={onSelect}
          />
  }
      
        {/* If there are no matches, display that */}
        <Grow in={filteredCTAs.length === 0 && ctas.length > 0}>
          <Grid item xs={12}>
            <Alert severity="info">No CTAs match your search</Alert>
          </Grid>
      </Grow>
      
      {/* If there are no CTAs, display that */}
        <Grow in={ctas.length === 0}>
          <Grid item xs={12}>
            <Alert severity="info">No CTAs found</Alert>
          </Grid>
        </Grow>
    </Grid>
  );
};

export default CTAList;

// Function to iterate through every collection/story/chapter and identify the # of uses of each CTA
const generateCTAUseTable = (collections) => {
  // Create an object to hold the number of uses of each CTA
  let ctaUses = {};

  // Iterate through every collection
  for (let collection of collections) {
    // Iterate through every story
    for (let story of collection.stories) {
      // Iterate through every chapter
      for (let chapter of story.chapters) {
        // Iterate through every CTA
        for (let cta of chapter.ctas) {
          // If the CTA is not in the object, add it
          if (!ctaUses[cta.id]) {
            ctaUses[cta.id] = 0;
          }

          // Increment the CTA's use count
          ctaUses[cta.id]++;
        }
      }
    }
  }

  return ctaUses;
};

// Function to check if a CTA is in use given a usage table
const isCTAInUse = (cta, ctaUses) => {
  // If the CTA is not in the table, it is not in use
  if (!ctaUses[cta.id]) return false;

  // If the CTA is in the table, but the use count is 0, it is not in use
  if (ctaUses[cta.id] === 0) return false;

  // Otherwise, it is in use
  return true;
};


const ViewSwitch = ({setViewType, viewType}) => {
  return (
    <div className="toggle-view-icon">
          <GridViewIcon
            onClick={() => setViewType('grid')}
            style={{
              color: viewType === "grid" ? '#1976d2' : 'grey',
              margin: 5
            }}
          fontSize="medium"
          />
          <FormatListBulletedIcon
             onClick={() => setViewType('list')}
             style={{
               color: viewType === "list" ? '#1976d2' : 'grey',
                margin: 5
            }}
          fontSize="medium"
            />
       </div>)
}