import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import LoadingBackdrop from "./components/LoadingBackdrop";
import { datadogLogs } from "@datadog/browser-logs";
import preval from "preval.macro";
import packageJson from "../package.json";
import env from "react-dotenv";

const dateTimeStamp = preval`module.exports = new Date().toISOString();`;

// Filter the timestamp value for use as datadog version #
const versionRegex = /[^a-z0-9_:./-]/g;
const sanatizedVersion = "v" + dateTimeStamp.replace(versionRegex, "");

datadogLogs.init({
  clientToken: "pubd827ab217f5caf300bc111e6cb49e421",
  site: "datadoghq.com",
  forwardErrorsToLogs: true,
  sampleRate: 100,
  service: "kahani-portal",
  env: process.env.NODE_ENV,
  version: sanatizedVersion,
  forwardConsoleLogs: ["log", "error", "warn"],
});

console.log(
  `%c Kahani Portal Version: ${packageJson.version}\nDeployed: ${dateTimeStamp}`,
  "color: #123456; font-size: 20px;"
);

console.log("Build ID: " + env?.COMMIT_REF);
console.log("Review ID: ", env?.REVIEW_ID);
console.log("Pull request: ", env?.PULL_REQUEST);
console.log("Branch: ", env?.BRANCH);

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<Loading />}>
      <App />
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

// class ErrorBoundary extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { hasError: false };
//   }

//   static getDerivedStateFromError(error) {
//     // Update state so the next render will show the fallback UI.
//     return { hasError: true };
//   }

//   componentDidCatch(error, errorInfo) {
//     // You can also log the error to an error reporting service
//     console.error(error);
//     console.error(errorInfo);
//   }

//   render() {
//     if (this.state.hasError) {
//       // You can render any custom fallback UI
//       return (
//         <div>
//           <h1>Something went wrong.</h1>
//         </div>
//       );
//     }

//     return this.props.children;
//   }
// }

// Center the loading indicator in the middle of the screen
function Loading() {
  return <LoadingBackdrop loading={true} />;
}
