import { getBaseURL } from "../utilities/parseCookie";
import { getOverrideAccountID } from "../auth/auth";
import { getEnvironment } from "../utilities/environment";

export let baseURL = "https://api.kahaniapp.com";

export const determineBaseURL = () => {
  let url = baseURL;

  // Check the current environment to define the API URL
  switch (getEnvironment()) {
    case "localhost":
      switch (window.location.hostname) {
        case "m1t.local":
          url = "http://m1t.local:5001";
          break;
        default:
          url = "http://localhost:5001";
      }
      break;
    case "development":
      switch (window.location.hostname) {
        case "kahani-dev.ngrok.io":
          url = "https://kahani-dev-api.ngrok.io";
          break;
        default:
          url = "https://api.dev.kahaniapp.com";
      }
      break;
    case "pull_request":
      url = "https://api.dev.kahaniapp.com";
      break;
    default:
      url = "https://api.kahaniapp.com";
  }

  // Finally, check for a cookie that will switch to a different API
  const customBaseURL = getBaseURL();
  if (customBaseURL) {
    url = customBaseURL;
  }

  return url;
};

baseURL = determineBaseURL();

const accountSlug = () => {
  // If an override account ID is set, use that
  const overrideAccountID = getOverrideAccountID();
  if (overrideAccountID) {
    return `accounts/${overrideAccountID}`;
  } else {
    // otherwise, the API will use the token
    return "account";
  }
};

export const shopifyOAuthURLs = {
  authorize: () => {
    return `${baseURL}/v0/public/shopify/oauth/authorize`;
  },
  callback: () => {
    return `${baseURL}/v0/public/shopify/oauth/callback`;
  },
};

export const collectionURLs = {
  list: () => {
    return `${baseURL}/v0/${accountSlug()}/collections`;
  },
  details: (collectionID) => {
    return `${baseURL}/v0/${accountSlug()}/collections/${collectionID}`;
  },
  storyOrder: (collectionID) => {
    return `${baseURL}/v0/${accountSlug()}/collections/${collectionID}/setStories`;
  },
  publish: (collectionID) => {
    return `${baseURL}/v0/${accountSlug()}/collections/${collectionID}/publish`;
  },
  publicCollection: (collectionID, accountID) => {
    return `${baseURL}/v0/public/accounts/${accountID}/collections/${collectionID}`;
  },
  previewURL: (collectionURL, accountID) => {
    // Base64 encode the collection URL
    collectionURL = encodeURIComponent(collectionURL);

    return `${baseURL}/v0/public/accounts/${accountID}/functions/collectionPreview?collectionURL=${collectionURL}`;
  },
  livePreviewUpdate: (token) => {
    return `${baseURL}/v0/${accountSlug()}/functions/livePreviewUpdate/${token}`;
  },
};

export const storyURLs = {
  list: () => {
    return `${baseURL}/v0/${accountSlug()}/stories`;
  },
  details: (storyID) => {
    return `${baseURL}/v0/${accountSlug()}/stories/${storyID}`;
  },
  chapterOrder: (storyID) => {
    return `${baseURL}/v0/${accountSlug()}/stories/${storyID}/setChapters`;
  },
};

export const chapterURLs = {
  list: () => {
    return `${baseURL}/v0/${accountSlug()}/chapters`;
  },
  details: (chapterID) => {
    return `${baseURL}/v0/${accountSlug()}/chapters/${chapterID}`;
  },
};

export const assetURLs = {
  list: () => {
    return `${baseURL}/v0/${accountSlug()}/assets`;
  },
  tags: () => {
    return `${baseURL}/v0/${accountSlug()}/functions/assetTagList`;
  },
  upload: () => {
    return `${baseURL}/v0/${accountSlug()}/assets`;
  },
  asset: (assetID) => {
    return `${baseURL}/v0/${accountSlug()}/assets/${assetID}`;
  },
};

export const ctaURLs = {
  list: () => {
    return `${baseURL}/v0/${accountSlug()}/CTAs`;
  },
  cta: (ctaID) => {
    return `${baseURL}/v0/${accountSlug()}/CTAs/${ctaID}`;
  },
};

export const adminURLs = {
  list: () => {
    return `${baseURL}/v0/admin/accounts`;
  },
};

export const accountURLs = {
  account: () => {
    return `${baseURL}/v0/${accountSlug()}`;
  },
  klaviyoLists: () => {
    return `${baseURL}/v0/${accountSlug()}/functions/klaviyoLists`;
  },
  setKlaviyoAPIKey: () => {
    return `${baseURL}/v0/${accountSlug()}/setKlaviyoAPIKey`;
  },
  changePassword: () => {
    return `${baseURL}/v0/${accountSlug()}/setPassword`;
  },
  pageTargeting: () => {
    return `${baseURL}/v0/${accountSlug()}/pageTargeting`;
  },
  pageTargetingURLTest: () => {
    return `${baseURL}/v0/${accountSlug()}/pageTargeting/testURLs`;
  },
  kvStore: () => {
    return `${baseURL}/v0/${accountSlug()}/kv_store`;
  },
  login: () => `${baseURL}/v0/public/generateToken`,
};

export const shopifyURLs = {
  shopifyStore: () => {
    return `${baseURL}/v0/${accountSlug()}/shopify`;
  },
  verifyAppBlockInstallation: () => {
    return `${baseURL}/v0/${accountSlug()}/shopify/theme/installed`;
  },
  verifyAppBlockSupport: () => {
    return `${baseURL}/v0/${accountSlug()}/shopify/theme/support`;
  },
};
