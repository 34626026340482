import { useQuery } from "react-query";

import { accountURLs } from "../constants/apiURLs";
import request from "../utilities/request";

export const queryKey = "KLAVIYO_LISTS_REQUEST";

export const useKlaviyoLists = () => {
  const { data, ...meta } = useQuery(
    queryKey,
    () => {
      return request().get(accountURLs.klaviyoLists());
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    klaviyoLists: data?.lists ?? [],
    ...meta,
  };
};
