import { useQuery } from "react-query";

import { adminURLs } from "../constants/apiURLs";
import request from "../utilities/request";

export const queryKey = "ACCOUNTS_REQUEST";

export const useAccounts = () => {
  const { data, ...meta } = useQuery(queryKey, () => {
    return request().get(adminURLs.list());
  });

  return {
    accounts: data?.accounts ?? [],
    ...meta,
  };
};
