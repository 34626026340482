import { useQuery } from "react-query";

import { collectionURLs } from "../constants/apiURLs";
import request from "../utilities/request";

export const queryKey = (collectionID) => {
  return ["COLLECTION_PUBLISH", collectionID];
};

export const useCollectionPublishStatus = (collectionID) => {
  const { data, ...meta } = useQuery(
    queryKey(collectionID),
    () => {
      return request().get(collectionURLs.publish(collectionID));
    },
    {
      useErrorBoundary: (error) => {
        console.error("useCollectionPublishStatus error1:");
        console.error(error.response);
        // @todo does returning something help?
      },
      onError: (error) => {
        console.error("useCollectionPublishStatus error2:");
        console.error(error);
        // @todo does returning something help?
      },
    }
  );

  // If data is an error, return it
  if (data instanceof Error) {
    console.debug("useCollectionPublishStatus error0:", data);
    console.debug("meta: ");
    console.debug(meta);
  }

  return {
    publishStatus: data ?? {},
    ...meta,
  };
};
