import React from "react";

import Page from "../components/Page";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  clearRequestAccountID,
  hasOverrideAccountID,
  overrideAccountName,
  useAuth,
} from "../auth/auth";
import { Divider, Typography } from "@mui/material";
import AccountList from "../modules/admin/AccountList";

const Admin = () => {
  const auth = useAuth();

  return (
    <Page title="Admin - Accounts">
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Paper
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Page.Title>Admin - Account Selector</Page.Title>
            <AccountList />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Alert severity="info">
            <AlertTitle>You are logged in as:</AlertTitle>
            <Typography variant="body1">{auth.user.name}</Typography>
          </Alert>
          <Divider style={{ margin: 30 }} />
          <AccountOverrideAlert />
        </Grid>
      </Grid>
    </Page>
  );
};

const AccountOverrideAlert = () => {
  const accountOverride = hasOverrideAccountID();

  return (
    <Alert severity={accountOverride ? "success" : "warning"}>
      <AlertTitle>Customer Selected:</AlertTitle>
      <Typography variant="body1">
        {accountOverride ? overrideAccountName() : "None selected yet"}
      </Typography>
      {accountOverride && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            clearRequestAccountID();
            window.location.href = "/admin";
          }}
          startIcon={<CancelIcon />}
          size="normal"
          style={{
            margin: 30,
          }}
        >
          Log Out
        </Button>
      )}
    </Alert>
  );
};

Admin.AccountOverrideAlert = AccountOverrideAlert;

export default Admin;
